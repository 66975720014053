/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.loader').fadeOut();
        $('.liquid').imgLiquid();

        var scroll = 0;
        var curScroll = 0;

        $(window).scroll(function(){
            scroll = $(window).scrollTop();

           if(scroll > 600) {
               $(".page-template-template-direct_sales .header").addClass("header--scrolled");
               $(".page-template-template-direct_sales_v2 .header").addClass("header--scrolled");
               $('.mobile_fixed').addClass('show');
           } else {
             $(".page-template-template-direct_sales .header").removeClass("header--scrolled");
             $(".page-template-template-direct_sales_v2 .header").removeClass("header--scrolled");
             $('.mobile_fixed').removeClass('show');
           }
        });

        $('.woocommerce ul.products li.product').matchHeight();
        $('footer h4.stext-301 ').matchHeight();
        $('.product-types .product p').matchHeight();

        /// subscription lander
        $('.open_order_form').click(function(){
          $('.order_form').show();
          $('body').addClass('modal_open');
        });

        $('.order_form .close').click(function(){
          $('.order_form').hide();
          $('.order_form .step_1').show();
          $('.order_form .step_2').hide();
          $('body').removeClass('modal_open');
        });

        $('.reviews_slider').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:"<i class='fas fa-chevron-left'></i>",
          nextArrow:"<i class='fas fa-chevron-right'></i>"
        });

        $('.logo_slider').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
          ]
        });

        $('.usps_mobile').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          arrows: false
        });

        $('.footer-strip').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

        $('.faq .title').click(function(){
          $(this).parent().toggleClass('open');
        });

        /* $('.goto_step2').click(function(){
          $('.order_form .step_1').hide();
          $('.order_form .step_2').show();
        }); */

        $('.form_lander input[name="billing_postcode"]').change(function(){
          var obj = $(this);
          var postalCode = obj.val();

          if(postalCode.length >= 4) {
            $.ajax({
               url: 'https://portal.premiumhealth.eu/postal/api.php?postal=' + postalCode,
               type: "GET",
               jsonpCallback: 'callback',
               crossDomain: true,
               success: function (response) {
                 if( response.status === "true") {
                   $('input[name="billing_address_1"]').val(response.street);
                   $('input[name="billing_city"]').val(response.place);
                   $('.adresinfo').show();

                 } else {
                   $('.adresinfo').show();
                 }

                 $(document.body).trigger("sticky_kit:recalc");
               },
            });
          }
        });

        $(".form_global").validate();

        var flag = false;

        $('.goto_step2').click(function(){
            var valid = $(".form_global").valid();

            if(!flag && valid == true) {
                flag = true;

                $('.order_form .step_1').hide();
                $('.order_form .step_2').show();

                var post = $('.order_form').attr('data-post');

                var leadFormData = {};
                $.each( $('.form_global').serializeArray(), function(i, field) {
                  leadFormData[field.name] = field.value;
                });

                leadFormData = JSON.stringify( leadFormData );

                $.post(
                    valglobal.ajax_url, {
                      'action': 'save_step1',
                      'nonce': valglobal.nonce,
                      'lead': leadFormData,
                      'post': post
                    },
                    function (response) {
                      console.log(response);
                      $('input[name="leadID"]').val(response.post);
                    }
                );

                setTimeout(function(){
                  flag = false;
                }, 5000);
            }
        });

        $('input[type=radio][name=payment_method]').change(function() {
            $('.payment_box').hide();
            $(this).parent().find('.payment_box').show();
        });

        $('.button.checkout').click(function(){
          var valid = $(".form_global").valid();

          if(!flag && valid == true) {
            $('.order_loader').show();
          }
        });


        //// shop JS
        /*[ Back to top ]
        ===========================================================*/
        var windowH = $(window).height()/2;

        $(window).on('scroll',function(){
            if ($(this).scrollTop() > windowH) {
                $("#myBtn").css('display','flex');
            } else {
                $("#myBtn").css('display','none');
            }
        });

        $('#myBtn').on("click", function(){
            $('html, body').animate({scrollTop: 0}, 300);
        });


        /*==================================================================
        [ Fixed Header ]*/
        var headerDesktop = $('.container-menu-desktop');
        var wrapMenu = $('.wrap-menu-desktop');

        if($('.top-bar').length > 0) {
            var posWrapHeader = $('.top-bar').height();
        }
        else {
            var posWrapHeader = 0;
        }


        if($(window).scrollTop() > posWrapHeader) {
            $(headerDesktop).addClass('fix-menu-desktop');
            $(wrapMenu).css('top',0);
        }
        else {
            $(headerDesktop).removeClass('fix-menu-desktop');
            $(wrapMenu).css('top',posWrapHeader - $(this).scrollTop());
        }

        $(window).on('scroll',function(){
            if($(this).scrollTop() > posWrapHeader) {
                $(headerDesktop).addClass('fix-menu-desktop');
                $(wrapMenu).css('top',0);
            }
            else {
                $(headerDesktop).removeClass('fix-menu-desktop');
                $(wrapMenu).css('top',posWrapHeader - $(this).scrollTop());
            }
        });


        /*==================================================================
        [ Menu mobile ]*/
        $('.btn-show-menu-mobile').on('click', function(){
            $(this).toggleClass('is-active');
            $('.menu-mobile').slideToggle();
        });

        var arrowMainMenu = $('.arrow-main-menu-m');

        for(var i=0; i<arrowMainMenu.length; i++){
            $(arrowMainMenu[i]).on('click', function(){
                $(this).parent().find('.sub-menu-m').slideToggle();
                $(this).toggleClass('turn-arrow-main-menu-m');
            })
        }

        $(window).resize(function(){
            if($(window).width() >= 992){
                if($('.menu-mobile').css('display') == 'block') {
                    $('.menu-mobile').css('display','none');
                    $('.btn-show-menu-mobile').toggleClass('is-active');
                }

                $('.sub-menu-m').each(function(){
                    if($(this).css('display') == 'block') { console.log('hello');
                        $(this).css('display','none');
                        $(arrowMainMenu).removeClass('turn-arrow-main-menu-m');
                    }
                });

            }
        });


        /*==================================================================
        [ Show / hide modal search ]*/
        $('.js-show-modal-search').on('click', function(){
            $('.modal-search-header').addClass('show-modal-search');
            $(this).css('opacity','0');
        });

        $('.js-hide-modal-search').on('click', function(){
            $('.modal-search-header').removeClass('show-modal-search');
            $('.js-show-modal-search').css('opacity','1');
        });

        $('.container-search-header').on('click', function(e){
            e.stopPropagation();
        });

        /*==================================================================
        [ Cart ]*/
        $('.js-show-cart').on('click',function(){
            $('.js-panel-cart').addClass('show-header-cart');
        });

        $('.js-hide-cart').on('click',function(){
            $('.js-panel-cart').removeClass('show-header-cart');
        });

        /*==================================================================
        [ Cart ]*/
        $('.js-show-sidebar').on('click',function(){
            $('.js-sidebar').addClass('show-sidebar');
        });

        $('.js-hide-sidebar').on('click',function(){
            $('.js-sidebar').removeClass('show-sidebar');
        });

        /*==================================================================
        [ +/- num product ]*/
        $('.btn-num-product-down').on('click', function(){
            var numProduct = Number($(this).next().val());
            if(numProduct > 0) $(this).next().val(numProduct - 1);
        });

        $('.btn-num-product-up').on('click', function(){
            var numProduct = Number($(this).prev().val());
            $(this).prev().val(numProduct + 1);
        });

        /// slick
        /*==================================================================
        [ Slick1 ]*/
        $('.wrap-slick1').each(function(){
            var wrapSlick1 = $(this);
            var slick1 = $(this).find('.slick1');


            var itemSlick1 = $(slick1).find('.item-slick1');
            var layerSlick1 = $(slick1).find('.layer-slick1');
            var actionSlick1 = [];


            $(slick1).on('init', function(){
                var layerCurrentItem = $(itemSlick1[0]).find('.layer-slick1');

                for(var i=0; i<actionSlick1.length; i++) {
                    clearTimeout(actionSlick1[i]);
                }

                $(layerSlick1).each(function(){
                    $(this).removeClass($(this).data('appear') + ' visible-true');
                });

                for(var i=0; i<layerCurrentItem.length; i++) {
                    actionSlick1[i] = setTimeout(function(index) {
                        $(layerCurrentItem[index]).addClass($(layerCurrentItem[index]).data('appear') + ' visible-true');
                    },$(layerCurrentItem[i]).data('delay'),i);
                }
            });


            var showDot = false;
            if($(wrapSlick1).find('.wrap-slick1-dots').length > 0) {
                showDot = true;
            }

            $(slick1).slick({
                pauseOnFocus: false,
                pauseOnHover: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                speed: 1000,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 6000,
                arrows: true,
                appendArrows: $(wrapSlick1),
                prevArrow:'<button class="arrow-slick1 prev-slick1"><i class="zmdi zmdi-caret-left"></i></button>',
                nextArrow:'<button class="arrow-slick1 next-slick1"><i class="zmdi zmdi-caret-right"></i></button>',
                dots: showDot,
                appendDots: $(wrapSlick1).find('.wrap-slick1-dots'),
                dotsClass:'slick1-dots',
                customPaging: function(slick, index) {
                    var linkThumb = $(slick.$slides[index]).data('thumb');
                    var caption = $(slick.$slides[index]).data('caption');
                    return  '<img src="' + linkThumb + '">' +
                            '<span class="caption-dots-slick1">' + caption + '</span>';
                },
            });

            $(slick1).on('afterChange', function(event, slick, currentSlide){

                var layerCurrentItem = $(itemSlick1[currentSlide]).find('.layer-slick1');

                for(var i=0; i<actionSlick1.length; i++) {
                    clearTimeout(actionSlick1[i]);
                }

                $(layerSlick1).each(function(){
                    $(this).removeClass($(this).data('appear') + ' visible-true');
                });

                for(var i=0; i<layerCurrentItem.length; i++) {
                    actionSlick1[i] = setTimeout(function(index) {
                        $(layerCurrentItem[index]).addClass($(layerCurrentItem[index]).data('appear') + ' visible-true');
                    },$(layerCurrentItem[i]).data('delay'),i);
                }

            });
        });


        /////// direct sales
        $('.reviews__slider').slick({
            infinite: true,
            arrows: false,
            adaptiveHeight: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });

        $(".form_ds").validate();

        $('button#place_order').click(function(e){
          var valid = $(".form_ds").valid();
          var product = $('input[name="product_id"]').val();
          var cheap = $('.cheapest_product').attr('data-product');

          if(product == cheap && valid == true) {
            e.preventDefault();
            $('.last-change').fadeIn();

          } else if(!flag && valid == true) {
            $('.order_loader').show();
          }
        });

        $('.button-last_change').click(function(e){
          var valid = $(".form_ds").valid();
          var product_id = $(this).attr('data-product');

          $('input[name="product_id"]').val(product_id);

          if(!flag && valid == true) {
            $('.order_loader').show();
            $('.last-change').hide();
            $("#checkout_form").submit();
          }
        });

        $('.no_thanks').click(function(e){
          var valid = $(".form_ds").valid();

          if(!flag && valid == true) {
            $('.order_loader').show();
            $('.last-change').hide();
            $("#checkout_form").submit();
          }
        });

        document.addEventListener("mouseleave", function(event){
          if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight))
          {
             if($('.leave_modal')) {
               $('.leave_modal').show();
             }
          }
        });

        $('.leave_modal .close_modal').click(function(){
          $('.leave_modal').hide();
        });

        $('.wrap-slick3').each(function(){
            $(this).find('.slick3').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 6000,

                arrows: true,
                appendArrows: $(this).find('.wrap-slick3-arrows'),
                prevArrow:'<button class="arrow-slick3 prev-slick3"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
                nextArrow:'<button class="arrow-slick3 next-slick3"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',

                dots: true,
                appendDots: $(this).find('.wrap-slick3-dots'),
                dotsClass:'slick3-dots',
                customPaging: function(slick, index) {
                    var portrait = $(slick.$slides[index]).find('.item-slick3').attr('data-thumb');
                    return '<div class="product_thumb" style="background:url(' + portrait + ') center center no-repeat;background-size:contain;"></div><div class="slick3-dot-overlay"></div>';
                },
            });
        });


        //// save leads direct sales
        $('.next-step-ds').click(function(){
          $('.order_loader').show();
          console.log('123');
          var leadFormData = {};

          $.each( $('#scrolldown').serializeArray(), function(i, field) {
            leadFormData[field.name] = field.value;
          });

          leadFormData = JSON.stringify( leadFormData );
          var redirectPage = $('#scrolldown input[name="page"]').val();
          var billing_first_name = $('#scrolldown input[name="billing_first_name"]').val();
          var billing_last_name = $('#scrolldown input[name="billing_last_name"]').val();
          var billing_email = $('#scrolldown input[name="billing_email"]').val();

          $.post(
              valglobal.ajax_url, {
                'action': 'save_ds_lead',
                'nonce': valglobal.nonce,
                'lead': leadFormData
              },
              function (response) {
                window.location.href = redirectPage + '?billing_first_name=' + billing_first_name + '&billing_last_name=' + billing_last_name + '&billing_email=' + billing_email;
              }
          );


        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
